import React from 'react';
import { graphql } from "gatsby";
import parse from 'html-react-parser';

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

//sections
import ThankyouSection from './sections/Thankyou/ThankyouSection';

const Thankyou = ({data}) => {
  const mainData = data.allWpPage.nodes[0].thankyouACF;
  return (
    <Layout>
      <Seo title="Thank You" />
      <div className="page-wrapper thankyou-page" id="thankyou-page-wrapper">
        <ThankyouSection 
            mainTitle={parse(mainData.thankyouTitle)}
            mainDescription={parse(mainData.thankyouDescription)}
            image1x={
              {
                sourceUrl: mainData.thankyouImage1x.sourceUrl,
                altText: mainData.thankyouImage1x.altText,
                width: mainData.thankyouImage1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.thankyouImage2x.sourceUrl,
                altText: mainData.thankyouImage2x.altText,
                width: mainData.thankyouImage2x.width,
              }
            }
        />
      </div>
    </Layout>
  )
}

export default Thankyou;

export const indexQuery = graphql`
{
  allWpPage(filter: {slug: {eq: "thankyou"}}) {
    nodes {
        thankyouACF {
            thankyouTitle
            thankyouDescription
            thankyouImage1x {
              altText
              sourceUrl
              width
            }
            thankyouImage2x {
              altText
              sourceUrl
              width
            }
          }
    }
  }
}`

